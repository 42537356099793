/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface Configuration {
  clientId: string;
  authApiBaseUrl?: string;
  appApiBaseUrl: string;
  enableSessionTimeout: string;
  storageSessionTimeKey?: string;
  expiryTimeInMinute: string;
  promptTimeBeforeIdleInMinute: string;
  auth0Domain: string;
  auth0CMSClientId: string;
  auth0APIClientId: string;
  auth0APISecret: string;
  apiEncryptionKey: string;
  webChatUrl: string;
}

export const fetchConfigData = createAsyncThunk<Configuration, void, { rejectValue: string }>(
  'config/fetchConfigData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/config.json');
      return await response.json();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
