import useConfig from 'Hooks/useConfig';
import { ReactNode, createContext, useState } from 'react';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import BackdropLoader from 'Components/BackdropLoader';
import { useNavigate } from 'react-router-dom';

const defaultAuthContextValue: {
  token: string | null;
  setToken: (value: string | null) => void;
} = {
  token: null,
  setToken: (value: string | null) => {}
};

export const AuthContext = createContext(defaultAuthContextValue);

/*
 * Wrapper for Auth0Provider
 */
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    config: { auth0Domain, auth0CMSClientId }
  } = useConfig();
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);

  const onRedirectCallback = (appState: AppState | undefined) => {
    console.log(
      'appState?.returnTo || window.location.pathname',
      appState,
      window.location.pathname
    );
    navigate(appState?.returnTo || window.location.pathname);
  };
  return auth0CMSClientId && auth0Domain ? (
    <AuthContext.Provider
      value={{
        token,
        setToken
      }}
    >
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0CMSClientId}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </AuthContext.Provider>
  ) : (
    <BackdropLoader />
  );
};

export default AuthProvider;
