import { ReactNode, useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { fetchConfigData } from 'redux/config/configThunk';

const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const dispatchThunk = async () => {
      await dispatch(fetchConfigData()).unwrap();
    };
    dispatchThunk();
  }, [dispatch]);
  return <>{children}</>;
};

export default ConfigProvider;
