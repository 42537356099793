export enum ReduxQueryTag {
  account = 'account',
  accounts = 'accounts',
  accountCopies = 'account-copies',
  accountCopy = 'account-copy',
  accountService = 'account-service',
  accountTwillioNumbers = 'account-twillio-numbers',
  intent = 'intent',
  slotType = 'slot-type',
  accountIntegration = 'account-integration',
  notify = 'notify'
}
