import tinycolor from 'tinycolor2';

// const primary = '#2AA5D3'
// const secondary = '#0469E3';

const primary = '#0469E3';
const secondary = '#2AA5D3';

const lightenRate = 7.5;
const darkenRate = 15;

export const paletteConfig = {
  light: {
    background: {
      // paper: '#f1f5f7',
    },
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString()
      // rose
      // main: '#E81823',
      // light: '#FC7092',
      // dark: '#90003B',
    }
  },
  dark: {
    border: {
      main: '#ffffff'
    }
  }
};

export const commonConfig = {
  // typography: {fontFamily: ['gotham'].join(',')},
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          height: '8px',
          width: '8px'
        },
        '*::-webkit-scrollbar-track': {
          background: '#fafafa'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#D1D1D1',
          borderRadius: '100px',
          opacity: '50%'
        },
        '*::-webkit-scrollbar-thumb:horizontal': {
          width: '8px',
          height: '300px',
          backgroundColor: '#D1D1D1',
          backgroundClip: 'padding-box',
          borderRight: '16px white solid'
        }
      }
    }
    // MuiButton: {
    //   defaultProps: {
    //     variant: 'outlined',
    //     color: 'secondary',
    //   },
    // },
  }
};
