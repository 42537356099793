// import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import BackdropLoader from 'Components/BackdropLoader';
import useAuth from 'Hooks/useAuth';
// import useConfig from 'Hooks/useConfig';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

const PersistLogin = () => {
  const { isAuthStateLoading, isLoggedIn } = useAuth();
  // const {
  //   config: { appApiBaseUrl }
  // } = useConfig();

  const [isLoaded, setIsLoaded] = useState(false);
  // const { getAccessTokenSilently } = useAuth0();
  // const fetchApiServiceToken = async () => {
  //   try {
  //     const tokenOptions: GetTokenSilentlyOptions = {
  //       authorizationParams: {
  //         audience: appApiBaseUrl,
  //         scope: '*'
  //       }
  //     };
  //     const token = await getAccessTokenSilently(tokenOptions);

  //     // const token = await getAccessTokenSilently();
  //     console.log('token =>', token);

  //     // const myHeaders = new Headers();

  //     // myHeaders.append("Content-Type", "application/json");
  //     // myHeaders.append("Cookie", "did=s%3Av0%3Ad08c7ec0-0ab9-11ee-a7e0-3f88b85a54c9.HS98otM96Q43dsL8OlClUrpvXKbHqscteUPPkklnb50; did_compat=s%3Av0%3Ad08c7ec0-0ab9-11ee-a7e0-3f88b85a54c9.HS98otM96Q43dsL8OlClUrpvXKbHqscteUPPkklnb50");
  //     // myHeaders.append('Access-Control-Allow-Origin', '*');
  //     // const raw = JSON.stringify({
  //     //     "client_id": auth0APIClientId,
  //     //     "client_secret": auth0APISecret,
  //     //     "audience": appApiBaseUrl,
  //     //     "grant_type": "client_credentials"
  //     //   });

  //     //   var requestOptions = {
  //     //     method: 'POST',
  //     //     headers: myHeaders,
  //     //     body: raw,
  //     //   };

  //     //   const response = await fetch(`https://${auth0Domain}/oauth/token`, requestOptions)
  //     //     const result = await response.json()
  //     //     console.log(result)
  //   } catch (err) {
  //     console.log('fetchApiServiceToken error =>', err);
  //   }
  // };

  useEffect(() => {
    if (!isLoaded && !isAuthStateLoading) {
      // if (isLoggedIn) {
      //   // fetchApiServiceToken();
      //   setIsLoaded(true);
      // } else {
      //   setIsLoaded(true);
      // }
      setIsLoaded(true);
    }
  }, [isAuthStateLoading, isLoggedIn, isLoaded]);
  return isLoaded ? <Outlet /> : <BackdropLoader />;
};

export default PersistLogin;
